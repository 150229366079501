exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*\n$lightest: #FFFFFF;\n$lighter: #EDE7D9;\n$main: #D5A021;\n$dark: #585858;\n$darker: #292929;\n$darkest: #000000;\n\n// Colors V1\n$lightest: #FFFFFF;\n$main: #849E9B;\n$dark: #868996;\n$darker: #5C6672;\n$darkest: #293342;\n\n\n// Colors V2\n$lightest: #FFFFFF;\n$main: #007AFF;\n$dark: #597488;\n$darker: #334354;\n$darkest: #2B3642;\n\n// Colors V3\n$lightest: #e2e2e2;\n$main: #839EE2;\n$dark: #444B60;\n$darker: #33343A;\n$darkest: #060A16;\n\n// Colors V4\n$lightest: #F4FDFF;\n$main: #48647A;\n$dark: #c0c0c0;\n$darker: #707B89;\n$darkest: #2A3542;\n*/\n.Page_Content__1AV02 {\n  padding-top: 56px; }\n\n.Page_Footer__VXVAf {\n  background-color: #000000;\n  color: #FFF;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  /* Set the fixed height of the footer here */\n  height: 70px;\n  line-height: 60px;\n  /* Vertically center the text there */ }\n\n.Page_Map__up4Je {\n  padding: 0; }\n\n.Page_Records__2ycnx {\n  background-color: #585858; }\n", ""]);

// exports
exports.locals = {
	"Content": "Page_Content__1AV02",
	"Footer": "Page_Footer__VXVAf",
	"Map": "Page_Map__up4Je",
	"Records": "Page_Records__2ycnx"
};