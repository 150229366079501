exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*\n$lightest: #FFFFFF;\n$lighter: #EDE7D9;\n$main: #D5A021;\n$dark: #585858;\n$darker: #292929;\n$darkest: #000000;\n\n// Colors V1\n$lightest: #FFFFFF;\n$main: #849E9B;\n$dark: #868996;\n$darker: #5C6672;\n$darkest: #293342;\n\n\n// Colors V2\n$lightest: #FFFFFF;\n$main: #007AFF;\n$dark: #597488;\n$darker: #334354;\n$darkest: #2B3642;\n\n// Colors V3\n$lightest: #e2e2e2;\n$main: #839EE2;\n$dark: #444B60;\n$darker: #33343A;\n$darkest: #060A16;\n\n// Colors V4\n$lightest: #F4FDFF;\n$main: #48647A;\n$dark: #c0c0c0;\n$darker: #707B89;\n$darkest: #2A3542;\n*/\n/*\n$lightest: #FFFFFF;\n$lighter: #EDE7D9;\n$main: #D5A021;\n$dark: #585858;\n$darker: #292929;\n$darkest: #000000;\n\n// Colors V1\n$lightest: #FFFFFF;\n$main: #849E9B;\n$dark: #868996;\n$darker: #5C6672;\n$darkest: #293342;\n\n\n// Colors V2\n$lightest: #FFFFFF;\n$main: #007AFF;\n$dark: #597488;\n$darker: #334354;\n$darkest: #2B3642;\n\n// Colors V3\n$lightest: #e2e2e2;\n$main: #839EE2;\n$dark: #444B60;\n$darker: #33343A;\n$darkest: #060A16;\n\n// Colors V4\n$lightest: #F4FDFF;\n$main: #48647A;\n$dark: #c0c0c0;\n$darker: #707B89;\n$darkest: #2A3542;\n*/\n.Navbar_Navbar__1JQTg {\n  background-color: #000000; }\n\n.Navbar_NavbarBrand__3eINW {\n  font-family: \"Noto Serif\", serif; }\n  @media (max-width: 767px) {\n    .Navbar_NavbarBrand__3eINW {\n      font-size: 0.8em; } }\n\n@media (max-width: 767px) {\n  .Navbar_NavbarBrandText__2NF2g {\n    display: none; } }\n\n.Navbar_NavBarBrandIcon__2fy9O {\n  height: 25px;\n  padding-right: 20px; }\n", ""]);

// exports
exports.locals = {
	"Navbar": "Navbar_Navbar__1JQTg",
	"NavbarBrand": "Navbar_NavbarBrand__3eINW",
	"NavbarBrandText": "Navbar_NavbarBrandText__2NF2g",
	"NavBarBrandIcon": "Navbar_NavBarBrandIcon__2fy9O"
};